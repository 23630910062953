import { MenuItem, Select } from "@mui/material"
import Utils from "../utils/utils"


export default function SelectTable({ selectedTable, setTable }) {

    return (
        <Select className='lg:w-60' value={selectedTable} onChange={(e) => {
            setTable(() => e.target.value)
        }}>
            {
                Object.keys(Utils.SHEET_MAPPER).map((key) => (
                    <MenuItem value={key} key={key}>{key}</MenuItem>
                ))
            }
        </Select>
    )
}