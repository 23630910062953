import * as React from 'react';
import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material";
import ReportSchema from "../../components/ReportSchemas";
import UploadExcelFile from '../../components/SelectTable';
import { useCallback, useRef, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import Utils from '../../utils/utils'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SelectTable from '../../components/SelectTable';

export default function ReportData() {
    // const [selectedSchema, setSelectedSchema] = useState({ label: "", sheetNumber: -1 })
    const [workbook, setWorkbook] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [totalRows, setTotalRows] = useState("")
    const [status, setStatus] = useState("")
    const [selectedTable, setTable] = useState("")
    const ref = useRef()

    const readExcelFile = useCallback(async function (file, ref) {
        // Create a FileReader instance
        const reader = new FileReader();

        // Return a promise to handle async processing
        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                console.log(workbook)
                ref.current.target = ""
                resolve(workbook)
            };

            reader.onerror = (error) => reject(error);
            // Read the file as an ArrayBuffer
            reader.readAsArrayBuffer(file);
        });
    }, [])

    return (
        <Box className="flex flex-col items-center lg:gap-10 justify-center p-2 gap-10">
            {/* <ReportSchema selectedSchema={selectedSchema} setSelectedSchema={setSelectedSchema} /> */}
            <Box className="border border-dotted p-2 rounded-lg bg-blue-100 shadow-md cursor-pointer border-black w-44" onClick={() => ref.current.click()}>
                {
                    loading ? <h2 className='text-center font-bold text-lg'>Loading...</h2> : <Box className="flex flex-col items-center"><CloudUploadIcon /> Upload Excel File</Box>}
                <input type="file" className="hidden" ref={ref} onChange={async (event) => {
                    setLoading(() => true)
                    setWorkbook(() => null)
                    const file = event.target.files[0];
                    if (!file) {
                        alert("Please select a file")
                        return
                    }
                    const workbookData = await readExcelFile(file, ref)
                    setWorkbook(() => workbookData)
                    setLoading(() => false)
                }} />
            </Box>
            {workbook && <Box className="w-full max-h-[700px] overflow-auto max-w-full">
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', fontSize: 14, backgroundColor: '#e8f1ff' }}>Sheet Name</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 14, backgroundColor: '#e8f1ff' }}>Table</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 14, backgroundColor: '#e8f1ff' }}>Action</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 14, backgroundColor: '#e8f1ff' }}>Progress Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {workbook.SheetNames.map((row, index) => (
                                <ExcelTableRow key={index} row={row} workbook={workbook} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
        </Box>
    )
}

function ExcelTableRow({ row, workbook }: { row: any, workbook: any, }) {

    const [selectedTable, setTable] = useState("")
    const [sending, setSending] = useState(false)
    const [status, setStatus] = useState("")

    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {row}
            </TableCell>
            <TableCell align="left">
                <SelectTable selectedTable={selectedTable} setTable={setTable} />
            </TableCell>
            <TableCell align="left"><Button variant='outlined' onClick={async () => {
                // Example: Read the first worksheet
                const worksheet = workbook.Sheets[row];
                console.log(workbook);
                // Convert the worksheet to JSON
                const jsonData = XLSX.utils
                    .sheet_to_json(worksheet, { header: 1 }).slice(1,)
                    ?.filter(ele => ele.length !== 0)?.map((ele) => {
                        return ele.reduce((a, b, c) => {
                            try {
                                a[Utils.SHEET_MAPPER[selectedTable][c]] = b?.trim()
                                return a
                            } catch (e) {
                                console.log(e.message, b)
                            }
                        }, {})
                    })?.filter(ele => ele);
                console.log(jsonData);
                try {
                    setSending(true)
                    const response = await fetch(`${process.env.REACT_APP_REPORT_BASE_URL}/api/report/${selectedTable}`, {
                        method: 'POST', // Specify the request method
                        headers: {
                            'Content-Type': 'application/json' // Indicate the content type as JSON
                        },
                        body: JSON.stringify(jsonData) // Convert the data object to a JSON string
                    })
                    const data = await response.json()
                    console.log(data, response);
                    setStatus(data?.message || data?.error)
                    setSending(false)
                } catch (err) {
                    setSending(false)
                    if (!selectedTable) { setStatus("Select table before uploading") }
                    console.log(err)
                }
            }}>
                {sending ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress color='warning' size={20} />
                </Box> : 'Upload'}
            </Button>
            </TableCell>
            <TableCell align="left">
                <div className='text-orange-400'>
                    {
                        status
                    }
                </div>
            </TableCell>
        </TableRow>
    )
}