
const Utils = {
    bufferToBase64(binary) {
        return binary.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
        }, '');
    },
    SHEET_MAPPER: {
        'subject-details': ['name', 'specialization_name', 'course_name', 'important_topics'],
        'latest-technologies': ['course_name', 'specialization_name', 'specialization_brief', 'latest_technologies', 'description', 'impact'],
        'self-projects': ['course_name', 'specialization_name', 'project_name', 'project_details', 'software_required', 'functional_skillset_required', 'detailed_plan', 'learning_outcomes', 'difficulty_level'],
        'job-roles': ['course_name', 'specialization_name', 'job_type', 'job_role', 'criteria', 'skillset', 'industry_trend'],
        'internships': ['course_name', 'specialization_name', 'job_role', 'internship', 'skillset_required', 'activities', 'salary', 'learning_outcome'],
        'industry-programs': ['course_name', 'specialization_name', 'industry_programs', 'organization', 'duration', 'eligibility', 'description'],
        'career-growth': ['course_name', 'specialization_name', 'job_role', 'year', 'sector', 'job_profile', 'salary_range'],
        'skills': ['course_name', 'specialization_name', 'job_role', 'skill', 'skill_details'],
        'certification': ['skill', 'level', 'course_name', 'platform', 'duration', 'description', 'cost', 'link'],
        'country': [
            "course_name",
            "specialization_name",
            "country",
            "job_trends_for_foreigners",
            "entry_criteria_for_jobs",
            "work_visa_requirements",
            "salary",
            "higher_education_opportunities",
            "entry_requirements_for_higher_education",
            "salary_for_indians",
            "visa_details_for_higher_education",
            "education_loan_details",
        ],
        'personal-skills': [
            'personal_skill',
            'level',
            'course_name',
            'platform',
            'duration',
            'description',
            'cost',
            'link'
        ]
    }
}


export default Utils;