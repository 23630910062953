import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';

const menuItems = [
    { name: 'Subject Details', value: 'subject-details' },
    { name: 'Latest Technologies', value: 'latest-technologies' },
    { name: 'Self Projects', value: 'self-projects' },
    { name: 'Job Roles', value: 'job-roles' },
    { name: 'Internships', value: 'internships' },
    { name: 'Industry Programs', value: 'industry-programs' },
    { name: 'Career Growth ( 10 years )', value: 'career-growth' },
    { name: 'Skills', value: 'skills' },
    { name: 'Certifications', value: 'certifications' },
    { name: 'Personal Skills', value: 'personal-skills' },
    { name: 'Country', value: 'country' },
];


export default function ReportSchema({ selectedSchema, setSelectedSchema }) {

    return (
        <div>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="demo-simple-select-filled-label">Report Schema</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedSchema.label}
                    onChange={(event) => {
                        setSelectedSchema((oldValue) => ({ ...oldValue, label: event.target.value }));
                    }}
                    label="Report Schema"
                >
                    {
                        menuItems?.map((ele) => (<MenuItem value={ele.value}>{ele.name}</MenuItem>))
                    }
                </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="demo-simple-select-filled-label">Select Sheet Number</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedSchema.sheetNumber}
                    onChange={(event) => {
                        setSelectedSchema((oldValue) => ({ ...oldValue, sheetNumber: event.target.value }));
                    }}
                    label="Select Sheet Number"
                >

                    {
                        menuItems?.map((ele, key) => (<MenuItem value={key}>{key}</MenuItem>))
                    }
                </Select>
            </FormControl>
        </div>
    );
}
